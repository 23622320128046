<template>
  <div id="login-page">
    <v-row>
      <v-col cols="12" md="7" class="ma-0 pa-0 primary">
      </v-col>
      <v-col cols="12" md="5" class="ma-0 pa-0">
        <div class="ma-0 pa-0">
          <div class="d-flex justify-center align-center text-center md:h-screen h-full-vh">
            <div class="w-60">
              <v-img src="@/assets/images/logos/PositiveMedia-logo.png"/>

              <v-form @submit.prevent="formSubmit" v-if="!two_factor_form">
                <v-text-field
                  prepend-icon="mdi-account" label="Email" v-model="user.email" class="mb-4"
                  :error-messages="$helpers.errorMsg('email', $v.user.email, 'Email')"
                  required @input="$v.user.email.$touch()" @blur="$v.user.email.$touch()"></v-text-field>
                <v-text-field
                  prepend-icon="mdi-account-lock" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'" name="input-10-2" label="Password" v-model="user.password"
                  :error-messages="$helpers.errorMsg('name', $v.user.password, 'Password')"
                  required @input="$v.user.password.$touch()" @blur="$v.user.password.$touch()"
                  @click:append="show = !show"></v-text-field>
                <v-btn type="submit" large block :loading="loading" :disabled="loading" class="mt-2"
                       color="primary white--text">
                  Sign In
                </v-btn>
              </v-form>

              <v-form @submit.prevent="twoFactorFormSubmit" v-else>
                <v-text-field
                  prepend-icon="mdi-account-lock" v-model="google2fa.otp"
                  :error-messages="$helpers.errorMsg('otp', $v.google2fa.otp, 'OTP')" placeholder="OTP"
                  @input="$v.google2fa.otp.$touch()" @blur="$v.google2fa.otp.$touch()" required></v-text-field>
                <v-btn
                  type="submit" large block :loading="loading" :disabled="loading" class="mt-2"
                  color="primary white--text">
                  Verify
                </v-btn>
              </v-form>

              <div class="my-4">
                <v-divider></v-divider>
              </div>

              <div class="text-center">
                <div class="d-flex justify-end flex-wrap">
                  <v-btn
                    type="button" text small color="primary" class="mb-2"
                    @click="$router.push('forgot-password')">Forgot Password
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {email, required} from "vuelidate/lib/validators";

export default {
  name: "Login",
  metaInfo: {
    title: "Login",
  },
  data() {
    return {
      show: false,
      two_factor_form: false,
      user: {
        email: "",
        password: "",
      },
      google2fa: {
        otp: ""
      }
    };
  },
  validations: {
    user: {
      email: { required, email },
      password: { required },
    },
    google2fa: {
      otp: {
        required,
        isNumeric(value) {
          return /^[0-9]+$/.test(value)
        },
        exactLength(value) {
          return value.length === 6
        },
      },
    }
  },
  computed: {
    ...mapGetters(["loading"])
  },
  methods: {
    ...mapActions(["login", "verify2fa"]),
    formSubmit() {
      this.$v.user.$touch();
      if (this.$v.user.$invalid) {
        return;
      }
      this.login(this.user)
        .then((res) => {
          if (!res || !res.data) {
            return;
          }

          this.setLoggedUser(res)
        })
        .catch((error) => {
          if (error.response.data.error[0] == "next_2fa_attempt") {
            this.two_factor_form = true;
            this.user.id = error.response.data.error[1];
          }
        });
    },
    twoFactorFormSubmit() {
      this.$v.google2fa.$touch()

      if (this.$v.google2fa.$invalid) {
        return
      }
      this.user.one_time_password = this.google2fa.otp;
      this.verify2fa(this.user).then((res) => {
        if (!res || !res.data) {
          return;
        }

        this.setLoggedUser(res)
      }).catch(() => {
      });
    },
    setLoggedUser(res) {
      this.$helpers.lsPush("curUser", res.data.data);
      this.$store.commit('updateAccessURLs', res.data.data.permissions)
      this.$store.commit("snackbar/SHOW_MESSAGE", {
        text: "Login Successfully",
        color: "success",
      });

      this.$router.push("/info");
    }
  },
};
</script>
